import { type MetaFunction } from '@remix-run/node'
import { Link } from '@remix-run/react'

export const meta: MetaFunction = () => [{ title: 'Papelea ' }]

export default function Index() {
	const countries = [
		{
			title: 'España',
			href: 'es/administraciones',
			description: 'Encuentra información del Estado, CCAA y ayuntamientos.',
		},
		{
			title: 'México',
			href: 'mx/administraciones',
			description: 'Encuentra información del Gobierno, estados y municipios.',
		},
		{
			title: 'Argentina',
			href: 'ar/administraciones',
			description:
				'Encuentra información del Gobierno, provincias y municipalidades.',
		},
	]

	return (
		<>
			<section className="space-y-6 pb-8 pt-6 md:pb-12 md:pt-10 lg:py-32">
				<div className="container flex max-w-[64rem] flex-col items-center gap-4 text-center">
					{/* <Link
						to={siteConfig.links.twitter}
						className="rounded bg-fuchsia-200 px-4 py-1.5 text-sm font-medium"
						target="_blank"
					>
						Siguenos en Twitter
					</Link> */}
					<h1 className="font-heading  text-xl font-black sm:text-5xl md:text-3xl lg:text-5xl">
						El mejor sitio para encontrar trámites e información legal{' '}
					</h1>
					<p className="max-w-[42rem] leading-normal text-muted-foreground sm:text-xl sm:leading-8">
						Nuestra misión es ayudarte con tus problemas legales. Para ello te
						ofrecemos la información más completa, y si lo deseas, contamos con
						profesionales seleccionados para acompañarte en todo lo que
						necesites
					</p>
					<form
						action="/buscar"
						method="GET"
						className="prose mt-8 flex items-center "
					>
						<input
							type="text"
							name="search"
							placeholder="Busca trámites..."
							className="rounded-l-md border border-gray-300 px-4 py-2 focus:border-blue-500 focus:outline-none"
						/>
						<button
							type="submit"
							className="rounded-r-md bg-amber-500 px-4 py-2 text-white hover:bg-amber-500"
						>
							Busca
						</button>
					</form>
				</div>
			</section>
			<section
				id="features"
				className="container space-y-6 bg-amber-100 py-8 dark:bg-transparent md:py-12 lg:py-24"
			>
				<div className="mx-auto flex max-w-[58rem] flex-col items-center space-y-4 text-center">
					<h2 className="font-heading  text-xl font-extrabold sm:text-4xl md:text-2xl lg:text-4xl">
						No dediques tu tiempo libre a hacer papeles
					</h2>
					<p className="max-w-[85%] leading-normal text-muted-foreground sm:text-lg sm:leading-7">
						La burocracia es necesaria, pero puede ser muy frustrante. Por eso
						queremos ofrecerte toda la información en un mismo lugar, y
						contribuir a hacerla más accesible
					</p>
				</div>
				<div className="mx-auto grid justify-center gap-4 sm:grid-cols-2 md:max-w-[64rem] md:grid-cols-3">
					{countries.map(item => (
						<div
							key={item.title}
							className="relative overflow-hidden rounded-lg border bg-background p-2"
						>
							<div className="flex h-[180px] flex-col justify-between rounded-md p-6">
								<div className="space-y-2">
									<h3 className="font-bold">{item.title}</h3>
									<p className="text-sm text-muted-foreground">
										{item.description}
									</p>
									<p className="mt-8">
										<Link
											className="mt-8 text-sm font-bold text-amber-700 hover:text-amber-700"
											to={item.href}
										>
											{' '}
											Encuentra tu administración
										</Link>
									</p>
								</div>
							</div>
						</div>
					))}
				</div>
			</section>
			<section id="open-source" className="container py-8 md:py-12 lg:py-24">
				<div className="mx-auto flex max-w-[58rem] flex-col items-center space-y-4 text-center"></div>
			</section>
		</>
	)
}
